import { AlternativeActions, InputField } from "@/components/auth";
import { AuthLayout } from "@/layouts/auth-layout";
import { AuthContext } from "@/lib/contexts/auth";

import { useStore } from "@/lib/hooks/use-store";
import { CaretRight } from "@phosphor-icons/react";
import { Button, Flex, Text } from "@radix-ui/themes";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthCard, ConfirmationCallout } from "./utils";

export function SignupPage() {
  const authStore = useStore(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const handleSubmit = (values: { email: string; password: string }) => {
    setLoading(true);
    authStore
      .signup(values.email)
      .then(() => {
        setEmailSent(true);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  if (emailSent) {
    return (
      <AuthLayout>
        <AuthCard>
          <ConfirmationCallout message="Welcome ! Please check your email and click on the link to complete your registration." />
        </AuthCard>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout>
      <AuthCard>
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={handleSubmit}
        >
          <Form>
            <Flex gap="3" direction="column">
              <Field
                name="email"
                as={InputField}
                type="email"
                placeholder="Email"
                autoComplete="email"
              />
              <Button size="3" type="submit" loading={loading}>
                Create Account
              </Button>
              <Text color="gray" size="1" style={{ width: 350 }}>
                Service is free of charge during the launch period.
              </Text>
            </Flex>
          </Form>
        </Formik>
      </AuthCard>
      <AlternativeActions>
        <div />
        <Button
          size="2"
          variant="ghost"
          onClick={() => navigate("/signin")}
          style={{ margin: 0 }}
        >
          Sign in
          <CaretRight size={13} />
        </Button>
      </AlternativeActions>
    </AuthLayout>
  );
}
