import styled from "styled-components";
import { FullCenter } from "../full-center";
import { Card, Flex, TextField } from "@radix-ui/themes";

export const AlternativeActions = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: var(--space-2);
`;

export const BackgroundStyled = styled(FullCenter)`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
`;

export const InputField = styled(TextField.Root).attrs({ size: "3" })`
  width: 100%;
`;

export const StyledContainer = styled.div`
  min-width: 350px;
`;

export const StyledCard = styled(Card)`
  width: 100%;
  background-color: var(--gray-1);
  box-shadow: var(--shadow-3);
`;

export const StyledCardHeader = styled(Flex).attrs({
  align: "center",
  gap: "2",
})`
  padding: 0px 0px var(--space-5) 0px;
`;
