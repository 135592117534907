import { Theme } from "@radix-ui/themes";
import "@radix-ui/themes/styles.css";
import * as Sentry from "@sentry/react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { logger } from "./lib/logger.ts";
import Router from "./router.tsx";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [],
  enabled: import.meta.env.PROD,
  tracesSampleRate: 1.0,
  environment: import.meta.env.PROD ? "production" : "development",
});
logger.setup();

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <Theme accentColor="orange" appearance="light">
      <Router />
    </Theme>
  </StrictMode>,
);
