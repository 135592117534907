export class Logger {
  enabled: boolean = true;

  constructor(enabled: boolean) {
    this.enabled = enabled;
  }

  info(...args: any[]) {
    if (!this.enabled) return;
    console.info(...args);
  }

  debug(...args: any[]) {
    if (!this.enabled) return;
    console.debug(...args);
  }

  warn(...args: any[]) {
    if (!this.enabled) return;
    console.warn(...args);
  }

  error(...args: any[]) {
    if (!this.enabled) return;
    console.error(...args);
  }

  setup() {
    let enabled = false;
    try {
      enabled = JSON.parse(localStorage.getItem("fgDebug")!);
    } catch {
      // ignore
    }
    this.enabled = enabled;
  }
}

export const logger = new Logger(true);
