import styled from "styled-components";

export const BackgroundDots = styled.div`
  --bg-size: 20px;
  position: absolute;
  inset: 0px;
  background: radial-gradient(circle, var(--gray-a6) 1%, transparent 11%) 0% 0% /
    1em 1em var(--gray-2);
  background-size: var(--bg-size) var(--bg-size);
  z-index: 0;
  height: 100%;
  width: 100%;
`;
