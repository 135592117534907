import styled from "styled-components";

export const BackgroundCheckered = styled.div`
  --tile-size: 50px;
  --background-size: var(--tile-size) var(--tile-size);
  --background-position: calc(var(--tile-size) / 2) calc(var(--tile-size) / 2);
  --gray-tile: var(--gray-3);
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0px;
  justify-content: center;
  align-items: center;
  background:
    -webkit-linear-gradient(
      45deg,
      var(--gray-tile) 25%,
      transparent 25%,
      transparent 75%,
      var(--gray-tile) 75%,
      var(--gray-tile) 0
    ),
    -webkit-linear-gradient(45deg, var(--gray-tile) 25%, transparent 25%, transparent
          75%, var(--gray-tile) 75%, var(--gray-tile) 0),
    white;
  background:
    -moz-linear-gradient(
      45deg,
      var(--gray-tile) 25%,
      transparent 25%,
      transparent 75%,
      var(--gray-tile) 75%,
      var(--gray-tile) 0
    ),
    -moz-linear-gradient(45deg, var(--gray-tile) 25%, transparent 25%, transparent
          75%, var(--gray-tile) 75%, var(--gray-tile) 0),
    white;
  background: linear-gradient(
      45deg,
      var(--gray-tile) 25%,
      transparent 25%,
      transparent 75%,
      var(--gray-tile) 75%,
      var(--gray-tile) 0
    ),
    linear-gradient(
      45deg,
      var(--gray-tile) 25%,
      transparent 25%,
      transparent 75%,
      var(--gray-tile) 75%,
      var(--gray-tile) 0
    ),
    white;
  background-repeat: repeat, repeat;
  background-position:
    0px 0,
    var(--background-position);
  -webkit-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  -webkit-background-origin: padding-box, padding-box;
  background-origin: padding-box, padding-box;
  -webkit-background-clip: border-box, border-box;
  background-clip: border-box, border-box;
  -webkit-background-size: var(--background-size), var(--background-size);
  background-size: var(--background-size), var(--background-size);
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
  -webkit-transform: scaleX(1) scaleY(1) scaleZ(1);
  transform: scaleX(1) scaleY(1) scaleZ(1);
`;
