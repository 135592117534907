import logoFull from "@assets/logo-full.svg";
import logoIcon from "@assets/logo-icon.svg";

interface LogoProps {
  height?: number;
  variant?: "icon" | "full";
}

function Logo({ height, variant = "full" }: LogoProps) {
  function getSrc() {
    switch (variant) {
      case "icon":
        return logoIcon;
      case "full":
        return logoFull;
      default:
        return logoFull;
    }
  }
  return <img src={getSrc()} alt="logo" height={height} />;
}

export default Logo;
