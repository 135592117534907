import { Flex } from "@radix-ui/themes";
import styled from "styled-components";

export const FullCenter = styled(Flex).attrs({
  align: "center",
  justify: "center",
  direction: "column",
})`
  height: 100%;
  width: 100%;
`;
