import { makeAutoObservable, reaction } from "mobx";
import { JwtToken } from "./variables/jwt-token";
import { Tracker } from "./contexts/analytics";
import axios, { AxiosInstance } from "axios";
import * as Sentry from "@sentry/react";

type User = {
  uid: string;
  username: string;
};

export class AuthManager {
  token: JwtToken;
  baseUrl: string;
  client: AxiosInstance;
  tracker: Tracker;
  user: User | null | undefined = undefined;

  constructor(baseUrl: string, token: JwtToken, tracker: Tracker) {
    this.token = token;
    this.baseUrl = baseUrl;
    this.client = axios.create({
      baseURL: baseUrl,
      withCredentials: true,
    });
    this.tracker = tracker;
    reaction(
      () => this.token.get(),
      (newToken) => {
        if (newToken) {
          this.authenticate();
        } else {
          this.setUser(null);
        }
      },
      {
        fireImmediately: true,
      },
    );
    makeAutoObservable(this, {
      baseUrl: false,
      client: false,
      tracker: false,
    });
  }

  getAuthenticatedClient() {
    const instance = axios.create({
      baseURL: this.baseUrl,
    });
    instance.interceptors.request.use(
      (request) => {
        request.headers["Authorization"] = `Bearer ${this.getToken()}`;
        return request;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
    instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            await this.refresh();
          } catch {
            await this.signout();
            throw error;
          }
          return instance(originalRequest);
        }
        return Promise.reject(error);
      },
    );
    return instance;
  }

  getClient() {
    return this.client;
  }

  getToken() {
    return this.token.get();
  }

  async authenticate() {
    const instance = this.getAuthenticatedClient();
    return instance
      .get("/editor/auth/me")
      .then((res) => this.setUser(res.data))
      .catch(() => {});
  }

  async signin(username: string, password: string): Promise<void> {
    try {
      const res = await this.client.post("/editor/auth/login", {
        username,
        password,
      });
      this.onUserAuth(res.data.token, res.data.user);
      this.tracker.trackEvent("Signin");
    } catch (e: any) {
      if (e.response?.status === 404) {
        throw new Error("Invalid username or password.");
      } else {
        throw new Error("An error occurred.");
      }
    }
  }

  async signup(username: string): Promise<void> {
    try {
      await this.client.post("/editor/account/create-account", {
        username,
      });
      this.tracker.trackEvent("Signup");
    } catch (e: any) {
      if (e.response?.status === 409) {
        throw new Error("Username already exists.");
      } else {
        throw new Error("An error occurred.");
      }
    }
  }

  async createPassword(
    token: string,
    password: string,
    passwordConfirm: string,
  ): Promise<void> {
    if (!token) throw new Error("Invalid link.");
    if (!password) throw new Error("Password is required.");
    if (password !== passwordConfirm)
      throw new Error("Passwords do not match.");
    try {
      const res = await this.client.post("/editor/account/reset-password", {
        new_password: password,
        token,
      });
      this.onUserAuth(res.data.token, res.data.user);
      this.tracker.trackEvent("CreatePassword");
    } catch {
      throw new Error("It looks like this confirmation link expired.");
    }
  }

  async forgotPassword(username: string): Promise<void> {
    try {
      await this.client.post("/editor/account/forgot-password", {
        username,
      });
      this.tracker.trackEvent("ForgotPassword");
    } catch (e: any) {
      const err = new Error("An error occurred.");
      err.stack = e.stack;
      throw err;
    }
  }

  async signout(): Promise<void> {
    await this.client.post("/editor/auth/logout");
    this.tracker.trackEvent("Signout");
    this.token.clear();
    this.setUser(null);
  }

  async refresh(): Promise<void> {
    const res = await this.client.post("/editor/auth/refresh");
    this.onUserAuth(res.data.token, res.data.user);
    this.tracker.trackEvent("Refresh");
  }

  onUserAuth(token: string, user: { uid: string; username: string }) {
    this.setToken(token);
    this.setUser(user);
  }

  setToken(token: string) {
    this.token.set(token);
  }

  setUser(user: User | null) {
    if (user) {
      this.user = user;
      this.tracker.identify(user.uid, user.username);
      Sentry.setUser({ id: user.uid, email: user.username });
    } else {
      this.user = null;
      this.tracker.clear();
      Sentry.setUser(null);
    }
  }
}
