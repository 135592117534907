import { AlternativeActions, InputField } from "@/components/auth";
import { AuthLayout } from "@/layouts/auth-layout";
import { AuthContext } from "@/lib/contexts/auth";

import { useStore } from "@/lib/hooks/use-store";
import { CaretRight } from "@phosphor-icons/react";
import { Button, Flex } from "@radix-ui/themes";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthCard, ErrorCallout } from "./utils";

export function SigninPage() {
  const authStore = useStore(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const handleSubmit = (values: { email: string; password: string }) => {
    setLoading(true);
    setError(null);
    authStore
      .signin(values.email, values.password)
      .then(() => {
        navigate("/editor");
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AuthLayout>
      <AuthCard>
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={handleSubmit}
        >
          <Form>
            <Flex gap="3" direction="column">
              {error && <ErrorCallout message={error} />}
              <Field
                name="email"
                as={InputField}
                type="email"
                placeholder="Email"
                autoComplete="email"
              ></Field>
              <Field
                name="password"
                as={InputField}
                type="password"
                placeholder="Password"
                autoComplete="current-password"
              ></Field>
              <Button size="3" type="submit" loading={loading}>
                Sign in
              </Button>
            </Flex>
          </Form>
        </Formik>
      </AuthCard>
      <AlternativeActions>
        <Button
          size="2"
          variant="ghost"
          color="gray"
          onClick={() => navigate("/forgot-password")}
          style={{ margin: 0 }}
        >
          Forgot Password?
        </Button>
        <Button
          size="2"
          variant="ghost"
          onClick={() => navigate("/signup")}
          style={{ margin: 0 }}
        >
          Create Account
          <CaretRight size={13} />
        </Button>
      </AlternativeActions>
    </AuthLayout>
  );
}
