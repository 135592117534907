import { createContext } from "react";
import * as amplitude from "@amplitude/analytics-browser";

export class Tracker {
  amplitude = amplitude;

  constructor() {
    if (import.meta.env.DEV) {
      this.amplitude.setOptOut(true);
    }
    if (import.meta.env.PROD) {
      this.amplitude.setOptOut(false);
    }
    this.amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, {
      serverZone: "EU",
      autocapture: {
        attribution: true,
        pageViews: true,
        sessions: true,
        fileDownloads: true,
      },
    });
  }

  identify(uid: string, username: string) {
    const identifyEvent = new amplitude.Identify();
    identifyEvent.set("id", uid);
    identifyEvent.set("email", username);
    amplitude.identify(identifyEvent);
    amplitude.setUserId(username);
  }

  trackEvent(eventName: string) {
    this.amplitude.track(eventName);
  }

  clear() {
    this.amplitude.reset();
  }
}

export const tracker = new Tracker();
export const AnalyticsContext = createContext(tracker);
