import { BackgroundStyled, StyledContainer } from "@/components/auth";
import { Background } from "@/components/background";

export function AuthLayout({ children }: { children: React.ReactNode }) {
  return (
    <BackgroundStyled>
      <Background.Dots style={{ zIndex: -1 }} />
      <StyledContainer>{children}</StyledContainer>
    </BackgroundStyled>
  );
}
