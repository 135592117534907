import { createAtom } from "mobx";

export class JwtToken {
  atom;
  key: string;

  constructor() {
    this.key = "jwtToken";
    this.atom = createAtom(this.key);
  }

  get(): string | null {
    const value = localStorage.getItem(this.key);
    this.atom.reportObserved();
    return value;
  }

  set(token: string | null) {
    const current = this.get();
    if (current !== token) {
      if (token == null) {
        localStorage.removeItem(this.key);
      } else {
        localStorage.setItem(this.key, token);
      }
      this.atom.reportChanged();
    }
  }

  clear() {
    this.set(null);
  }

  isValid() {
    try {
      this.validate();
      return true;
    } catch {
      return false;
    }
  }

  validate() {
    const rawToken = this.get();
    if (!rawToken) throw new AuthenticationError("No token found");
    try {
      parseJwt(rawToken);
      return true;
    } catch {
      throw new AuthenticationError("Invalid token");
    }
  }
}

export class AuthenticationError extends Error {}
type DecodedToken = any;

function parseJwt(token: string): DecodedToken {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );
  return JSON.parse(jsonPayload);
}

export const jwtToken = new JwtToken();
