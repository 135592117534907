import { useAuthManager } from "@/lib/hooks/use-auth";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { ReactNode } from "react";

export const AuthSwitch = observer(
  ({
    onAuthenticated,
    onUnauthenticated,
  }: {
    onAuthenticated?: ReactNode;
    onUnauthenticated?: ReactNode;
  }) => {
    const authManager = useAuthManager();
    const user = computed(() => authManager.user).get();
    if (user === undefined) {
      return null;
    } else if (user === null) {
      return onUnauthenticated;
    } else {
      return onAuthenticated;
    }
  },
);
