import { Box, Heading } from "@radix-ui/themes";
import { ReactNode } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { FullCenter } from "./components/full-center";
import { AuthSwitch } from "./components/switches/auth-switch";
import { ForgotPasswordPage } from "./pages/auth/forgot-password";
import { JoinPage } from "./pages/auth/join";
import { ResetPasswordPage } from "./pages/auth/reset-password";
import { SigninPage } from "./pages/auth/signin";
import { SignupPage } from "./pages/auth/signup";

export function RequireAuth({
  fallback,
  children,
}: {
  fallback: string;
  children: ReactNode;
}) {
  return (
    <AuthSwitch
      onUnauthenticated={<Navigate to={fallback} />}
      onAuthenticated={children}
    />
  );
}

function RequireAnonymous({
  fallback,
  children,
}: {
  fallback: string;
  children: ReactNode;
}) {
  return (
    <AuthSwitch
      onUnauthenticated={children}
      onAuthenticated={<Navigate to={fallback} />}
    />
  );
}

function isMobile() {
  if (window.matchMedia("(any-hover:none)").matches) {
    return true;
  } else {
    return false;
  }
}

const router = createBrowserRouter([
  {
    path: "/editor",
    async lazy() {
      if (isMobile()) {
        return { Component: NoMobilePage };
      }
      const { EditorPage } = await import("./pages/editor");
      return { Component: EditorPage };
    },
  },
  {
    path: "/signin",
    element: (
      <RequireAnonymous fallback="/editor">
        <SigninPage />
      </RequireAnonymous>
    ),
  },
  {
    path: "/signup",
    element: (
      <RequireAnonymous fallback="/editor">
        <SignupPage />
      </RequireAnonymous>
    ),
  },
  {
    path: "/forgot-password",
    element: (
      <RequireAnonymous fallback="/editor">
        <ForgotPasswordPage />
      </RequireAnonymous>
    ),
  },
  {
    path: "/join/:token",
    element: (
      <RequireAnonymous fallback="/editor">
        <JoinPage />
      </RequireAnonymous>
    ),
  },
  {
    path: "/reset-password/:token",
    element: (
      <RequireAnonymous fallback="/editor">
        <ResetPasswordPage />
      </RequireAnonymous>
    ),
  },
  {
    path: "*",
    element: <Navigate to="/signin" replace />,
  },
]);

function Router() {
  return <RouterProvider router={router} />;
}

function NoMobilePage() {
  return (
    <FullCenter style={{ height: "100vh", width: "100vw" }}>
      <Box p="3">
        <Heading weight="bold" size="6" align="center">
          Sorry, we currently do not support mobile or tablet devices.
        </Heading>
      </Box>
    </FullCenter>
  );
}

export default Router;
