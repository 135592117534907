import { StyledCard, StyledCardHeader } from "@/components/auth";
import Logo from "@/components/logo";
import { Callout } from "@radix-ui/themes";
import { CheckCircle } from "@phosphor-icons/react";

export function AuthCard({ children }: { children: React.ReactNode }) {
  return (
    <StyledCard size="2">
      <StyledCardHeader>
        <Logo height={25}></Logo>
      </StyledCardHeader>
      {children}
    </StyledCard>
  );
}

export function ConfirmationCallout({ message }: { message: string }) {
  return (
    <Callout.Root color="green">
      <Callout.Icon>
        <CheckCircle size={20} color="var(--green-10)" />
      </Callout.Icon>
      <Callout.Text>{message}</Callout.Text>
    </Callout.Root>
  );
}

export function ErrorCallout({ message }: { message: string }) {
  return (
    <Callout.Root color="red">
      <Callout.Text>{message}</Callout.Text>
    </Callout.Root>
  );
}
